


<template >
  <section class="pay-page">
    <div class="home-pay">
      <div class="clip-iphone">
        <img
          src="@/assets/pay-logo.svg"
          class="logo-pay"
          width="500px"
          alt=""
        />
        <img src="@/assets/phone-pay.png" class="phone-pay" alt="" />
      </div>
      <v-container>
        <h1 class="text-center">
          La manera <span class="facil-style">fácil</span> de
          <span style="color: #ff5900">cobrar</span>
        </h1>
        <p class="text-center">
          Ahora puedes aceptar pagos con todos los métodos electrónicos <br />
          disponibles (Tarjetas de Crédito/Débito, Billeteras Electrónicas)
        </p>
      </v-container>
    </div>
    <v-container class="my-12 flex-center-">
      <img
        class="text-center mb-12"
        width="200px"
        src="@/assets/icon/pay.svg"
        alt=""
      />
      <h1 class="text-center mb-12">
        Un ecosistema de <span style="color: #ff5900"> pagos digital</span>
      </h1>
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="6"
          lg="4"
          v-for="(item, index) in pays"
          :key="index"
        >
          <div class="wrap-cuadro" :style="item.style">
            <div class="icon-img">
              <img :src="item.icon" alt="" width="60%" srcset="" />
            </div>
            <img :src="item.logo" alt="" height="55px" class="mb-2" srcset="" />
            <p>{{ item.description }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" sm="12" lg="12">
          <div class="icon-wrap-wallets">
            <img
              :src="i"
              alt=""
              class="wallets"
              srcset=""
              v-for="(i, index) in wallet"
              :key="index"
            />
            <img
              src="@/assets/card-3d.png"
              width="120px"
              height="120px"
              alt=""
              srcset=""
            />
          </div>
        </v-col>
        <!-- <v-col
          class="flex-center-"
          style="align-items: start; padding-left: 10%"
        >
          <h1 class="title-sections">
            <span style="color: #ff5900">Integraciones</span>
          </h1>
          <p>
            Paga con cualquier billetera digital <br />
            o con tu tarjeta de crédito/debito.
          </p>
        </v-col> -->
      </v-row>
    </v-container>

    <v-container class="redes-pay mt-12">
      <div class="wrap-all-content">
        <div class="wrap-stiky">
          <img src="@/assets/phone-pay.png" width="300px" alt="" />
        </div>
        <div class="wrap-content reveal">
          <div class="wrap-canales">
            <img
              src="@/assets/paylink.svg"
              class="mb-4"
              height="50px"
              alt=""
              srcset=""
            />
            <h2>
              Crea y
              <span style="color: #ff5900">recibe pagos</span> a través de redes
              sociales o email.
            </h2>
            <div class="canales">
              <img src="@/assets/I.svg" width="50px" alt="" />
              <img src="@/assets/F.svg" width="50px" alt="" />
              <img src="@/assets/W.svg" width="50px" alt="" />
              <img src="@/assets/sms.png" width="50px" alt="" />
            </div>
            <!-- <ul>
              <li>WhatsApp</li>
              <li>Facebook</li>
              <li>Instagram</li>
              <li>Correo electrónico</li>
              <li>SMS</li>
              <li>Código QR</li>
            </ul> -->
          </div>
          <div class="wrap-canale my-12 reveal">
            <h1 class="title-sections">
              Beneficios de <br />
              <span style="color: #ff5900"> Ocho Paylink</span>
            </h1>
            <p class="">
              Recibe pagos con tarjetas de crédito/débito o billeteras
              electrónicas.
            </p>

            <div class="wrap-beneficios">
              <div
                class="wrap-flex mt-4 reveal"
                v-for="(item, index) in beneficio"
                :key="index"
              >
                <div class="icon" :style="`background-color: ${item.color}`">
                  <img :src="item.icon" alt="" />
                </div>
                <h3>{{ item.title }}</h3>
                <p>{{ item.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <v-container class="mt-12">
      <div class="wrap-all-content">
        <div class="wrap-content reveal text-center-mobile">
          <img src="@/assets/payQr.svg" alt="" srcset="" />
          <h2>
            Recibe pagos <span style="color: #ff5900">contactless</span> a
            través de QR
          </h2>
          <div class="wrap-pasos reveal" v-for="p in payQr" :key="p.id">
            <div class="num" :style="`background-color:${p.color}`">
              <span>{{ p.id }}</span>
            </div>
            <h2>{{ p.title }}</h2>
            <p class="font-weight-light">{{ p.description }}</p>
          </div>
        </div>
        <div class="wrap-stiky">
          <img src="@/assets/phoneQr.png" width="80%" alt="" />
        </div>
      </div>
    </v-container>
    <v-container class="flex-center- mb-12">
      <img src="@/assets/payEcom.svg" class="my-12" alt="" srcset="" />

      <v-row>
        <v-col cols="12" md="6" sm="6">
          <img
            src="@/assets/tienda.png"
            style="border-radius: 10px"
            width="100%"
            alt=""
            srcset=""
          />
        </v-col>
        <v-col cols="12" md="6" sm="6" class="flex-center- reveal text-center-mobile">
          <h1 class="title-sections">
            <span style="color: #ff5900">Acepta pagos</span> <br />
            En tu sitio web
          </h1>

          <p class="mt-4">Integra un método de pago a tu sitio web.</p>
          <h3>¿Necesitas ayuda?</h3>

          <v-btn
            color="#ff5900"
            href="https://menu.hn/EJE-01"
            target="_blank"
            class="mt-2"
            width="200px"
            style="color: #fff"
          >
            ver demo</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import descarga from "@/components/btnDescarga";
import store from "@/store/index.js";
import ScrollReveal from "scrollreveal";

export default {
  name: "Pay",
  components: {
    descarga,
  },
  data() {
    return {
      wallet: [
        require("@/assets/icon/dilo.png"),
        // require("@/assets/icon/tengo.png"),
        // require("@/assets/icon/tigoMoney.png"),
        // require("@/assets/card-3d.png"),
        // require("@/assets/icon/mastercard.png"),
      ],
      pays: [
        {
          logo: require("@/assets/paylink.svg"),
          icon: require("@/assets/icon/link.svg"),
          style: {
            "background-color": "#AFF9D9",
            color: "#202E4F",
          },
          description:
            "Crea links de pago y compártelos con tus clientes a través de redes sociales o email.",
        },
        {
          logo: require("@/assets/payQr.svg"),
          icon: require("@/assets/icon/qr.svg"),
          style: {
            "background-color": "#BBE5FF",
            color: "#202E4F",
          },
          description: "Acepta pagos digitales directo en tu negocio.",
        },
        {
          logo: require("@/assets/payEcom.svg"),
          icon: require("@/assets/icon/store.svg"),
          style: {
            "background-color": "#FFE2AE",
            color: "#202E4F",
          },
          description: "Integra un método de pago a tu sitio web ya existente.",
        },
      ],
      beneficio: [
        {
          icon: require("@/assets/icon/edit.svg"),
          title: "Personalización",
          color: "#FFC634",
          subtitle:
            "Personaliza Ocho PayLink con tu logo y los colores de tu empresa",
        },
        {
          icon: require("@/assets/icon/share.svg"),
          title: "Comparte",
          color: "#97FCCF",
          subtitle:
            "Envía el enlace por cualquier canal de comunicación: Facebook, Instagram, Whats app",
        },
        {
          icon: require("@/assets/icon/report.svg"),
          title: "Reportes de pagos",
          color: "#BBE5FF",
          subtitle: "Descarga reportes de pagos desde tu panel ochoPay",
        },
      ],
      payQr: [
        {
          id: 1,
          title: "Genera QR",
          color: "#BBE5FF",
          description: "Tu cliente escanea y paga",
        },
        {
          id: 2,
          title: "Valida el monto",
          color: "#AFF9D9",
          description: "Completa los campos",
        },
        {
          id: 3,
          title: "Método de pago",
          color: "#FF5900",
          description:
            "Recibe tu pago a través de billeteras electrónicas\no tarjeta de crédito / débito",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    window.scrollTo(0, 0);
    ScrollReveal({
      easing: "ease-in-out",
      reset: true,
      delay: 100,
      distance: "50px",
      origin: "bottom",
    }).reveal(".reveal");
    // this.startAnimations();
  },
  methods: {},
};
</script>
<style  src="@/main.scss" lang="scss"></style>
<style lang="scss" >
@import "@/_responsive.scss";
.flex-center- {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
html[data-scroll="0"] {
  .pay-page {
    min-height: auto;
    transition: 0.5s ease-in-out;
    .home-pay {
      width: 100%;
      height: 100vh;
      transition: 0.5s ease-in-out;
      .clip-iphone {
        width: 100%;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ff5900;
        transition: 0.5s ease-in-out;
        padding-bottom: 100px;
        background-image: radial-gradient(
          circle at 50% 50%,
          #ff5900,
          #ff823e
        ) !important;
        clip-path: ellipse(80% 80% at 50% 15%);
        @include responsive(mobile) {
          clip-path: ellipse(120% 70% at 50% 20%);
        }
        .phone-pay {
          transition: 1s ease-in-out;
          position: absolute;
          width: 350px;
          top: 37vh;
          @include responsive(mobile) {
            top: 36vh;
          }
        }
      }
    }
  }
}
.pay-page {
  min-height: auto;
  transition: 0.5s ease-in-out;
  color: #202e4f;
  background-color: #fff;
  .home-pay {
    width: 100%;
    height: auto;
    transition: 0.5s ease-in-out;
    .clip-iphone {
      transition: 0.5s ease-in-out;
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #ff5900;
      padding-bottom: 200px;
      background-image: radial-gradient(
        circle at 50% 50%,
        #ff5900,
        #ff823e
      ) !important;
      clip-path: ellipse(80% 70% at 50% 15%);
      @include responsive(mobile) {
        clip-path: ellipse(80% 70% at 50% 5%);
      }
      .phone-pay {
        transition: 1s ease-in-out;
        position: absolute;
        width: 350px;
        top: 680px;
      }
    }
  }
}
.redes-pay {
  min-height: 100vh;
}
@include responsive(mobile) {
  .logo-pay {
    width: 80%;
  }
}
.wrap-all-content {
  height: auto;
  display: flex;
  justify-content: flex-end;
  @include responsive(mobile) {
    flex-direction: column;
  }
  .wrap-stiky {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include responsive(mobile) {
      width: 100%;
    }

    img {
      position: sticky !important;
      top: 100px;
    }
  }
  .wrap-content {
    width: 50%;
    @include responsive(mobile) {
      width: 100%;
    }
    .wrap-canales {
      height: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 100px;
      margin-bottom: 100px;
      .canales {
        padding: 20px 0px;
        border-radius: 20px;
        display: flex;
        width: 300px;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
  }
}
.wrap-cuadro {
  width: 100%;
  height: 250px;

  border-radius: 29px;
  padding: 20px;
  position: relative;
  padding-top: 80px;
  .icon-img {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon-wrap-wallets {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;

  .wallets {
    width: 70px;
    height: 70px;
    margin: 40px;
    border-radius: 15px;
    background-color: #00cdbc;
  }
}

.title-sections {
  font-size: 50px;
  line-height: 60px;
}

.wrap-beneficios {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  .wrap-flex {
    padding-left: 60px;
    position: relative;
    .icon {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 0px;
      top: 5px;
      background-color: #f2f2f2;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.wrap-pasos {
  width: 100%;
  display: flex;

  padding-top: 50px;
  flex-direction: column;
  @include responsive(mobile) {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
  .num {
    width: 50px;
    height: 50px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-weight: 800;
  }
  h2 {
    font-weight: 600;
  }
}
.facil-style {
  background-color: #ff5900e0;
  color: #fff;
  padding: 3px 10px;
  border-radius: 20px;
}
</style>
